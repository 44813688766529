import React from 'react';
import cls from 'classnames';
import { useBi, useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import { useCurrentStateAndParams, useRouter } from '@wix/tpa-router/react';
import { useSettings, useStyles } from '@wix/tpa-settings/react';
import { useSelector } from 'react-redux';

import { debounce } from 'lodash';

import { Search as SearchIcon } from '@wix/wix-ui-icons-common/on-stage';
import { EGroupPartition } from 'api/groups/types';

import { Box } from 'wui/Box';
import { Show } from 'wui/Show';
import { Wire } from 'wui/Wire';
import { Paper } from 'wui/Paper';
import { Search } from 'wui/Search';
import { Typography } from 'wui/Typography';
import { Dropdown, Option } from 'wui/Dropdown';
import { TextField } from 'wui/TextField';
import { TextButton } from 'wui/TextButton';

import { useController } from 'common/context/controller';

import settingsParams from 'Groups/settingsParams';
import stylesParams from 'Groups/stylesParams';
import { GroupsRequestSort } from 'settings/consts';

import {
  selectCanCreateGroup,
  selectCanCreateGroupRequests,
} from 'store/selectors';

import { HEADER_CREATE_GROUP_BUTTON } from './dataHooks';

import classes from './Header.scss';
import { groupsLivesiteClick } from '@wix/bi-logger-groups/v2';
import { useBiParams } from 'common/hooks/useBiParams';

export function Header() {
  const settings = useSettings();
  const styles = useStyles();
  const { isMobile } = useEnvironment();
  const { t } = useTranslation();
  const bi = useBi();
  const biParams = useBiParams();

  const router = useRouter();
  const { params } = useCurrentStateAndParams();
  const { application$ } = useController();

  const [query, setQuery] = React.useState('');
  const delayedQuery = React.useCallback(debounce(searchGroups, 500), []);

  React.useEffect(() => {
    setQuery(params.title || '');
  }, [params.title || '']);

  const canCreateGroup = useSelector(selectCanCreateGroup);
  const canCreateGroupRequests = useSelector(selectCanCreateGroupRequests);

  const showCreateGroupButton = canCreateGroup || canCreateGroupRequests;

  const { htmlTag } = styles.get(stylesParams.listTitleFont);

  if (isMobile) {
    return (
      <Box direction="vertical" gap="SP0">
        <Box align="space-between" padding="SP5">
          <Wire
            cssVarName="showHeaderTitle"
            legacyFallback={settings.get(settingsParams.showHeaderTitle)}
          >
            <Typography
              as={htmlTag as React.ElementType}
              variant="h2-20"
              data-hook="group-list-title"
              className={cls(classes.title, classes.mobile)}
            >
              {settings.get(settingsParams.groupListTitle)}
            </Typography>
          </Wire>
          <Show if={showCreateGroupButton}>
            <TextButton
              onClick={openCreateGroupDialog}
              data-hook={HEADER_CREATE_GROUP_BUTTON}
            >
              {t('groups-web.btn.create-new')}
            </TextButton>
          </Show>
        </Box>
        <Wire
          cssVarName="showSearchSorting"
          legacyFallback={settings.get(settingsParams.showSearchSorting)}
        >
          <Paper sideBorders={false}>
            <Box direction="vertical" padding="SP5">
              <TextField
                className={classes.mobileSearch}
                prefix={<SearchIcon />}
                value={query}
                placeholder={t('groups-web.search.placeholder')}
                onChange={(evt) => handleQueryChange(evt.target?.value)}
              />
            </Box>
          </Paper>
        </Wire>
      </Box>
    );
  }

  return (
    <Box align="space-between">
      <Box gap="SP6" verticalAlign="middle">
        <Wire
          cssVarName="showHeaderTitle"
          legacyFallback={settings.get(settingsParams.showHeaderTitle)}
        >
          <Typography
            as={htmlTag as React.ElementType}
            variant="h2-20"
            data-hook="group-list-title"
            className={classes.title}
          >
            {settings.get(settingsParams.groupListTitle)}
          </Typography>
        </Wire>
        <Wire
          cssVarName="showSearchSorting"
          legacyFallback={settings.get(settingsParams.showSearchSorting)}
        >
          <Dropdown
            className={classes.sortDropdown}
            onChange={handleSortChange}
            label={t('groups-web.group-list.sort') + ':'}
            placeholder={t('groups-web.members.filter.all')}
            value={params.sort}
            options={[
              {
                isSelectable: true,
                id: GroupsRequestSort.RECENT_ACTIVITY,
                value: t('groups-web.group-list.sort.recent-activity'),
              },
              {
                isSelectable: true,
                id: GroupsRequestSort.NAME,
                value: t('groups-web.group-list.sort.alphabetical'),
              },
              {
                isSelectable: true,
                id: GroupsRequestSort.MEMBERS_COUNT,
                value: t('groups-web.group-list.sort.member-count'),
              },
            ]}
          />
        </Wire>
      </Box>
      <Box gap="SP6" verticalAlign="middle">
        <Wire
          cssVarName="showSearchSorting"
          legacyFallback={settings.get(settingsParams.showSearchSorting)}
        >
          <Search
            withClearButton
            className={classes.search}
            autoFocus={!!params.title}
            value={query}
            onChange={(evt) => handleQueryChange(evt?.target?.value)}
            placeholder={t('groups-web.search.placeholder')}
            onClear={() => handleQueryChange('')}
          />
        </Wire>
        <Show if={showCreateGroupButton}>
          <TextButton
            data-hook={HEADER_CREATE_GROUP_BUTTON}
            onClick={openCreateGroupDialog}
          >
            {t('groups-web.btn.create-new')}
          </TextButton>
        </Show>
      </Box>
    </Box>
  );

  // function handleOrderChange() {
  //   router.go('.', { order: params.order === 'ASC' ? 'DESC' : 'ASC' });
  // }

  function openCreateGroupDialog() {
    bi.report(
      groupsLivesiteClick({
        screen_name: biParams.groupsScreen,
        button_name: 'create_group',
      }),
    );
    application$.showDialog({ dialog: 'createGroup' });
  }

  function handleSortChange(option: Option) {
    if (option.id === params.sort) {
      return;
    }

    bi.report(
      groupsLivesiteClick({
        screen_name: biParams.groupsScreen,
        button_name: 'sort_by',
      }),
    );

    router.go('.', { sort: option.id });
  }

  function handleQueryChange(query: string) {
    setQuery(query);
    delayedQuery(query);
  }

  function searchGroups(value: string) {
    bi.report(
      groupsLivesiteClick({
        screen_name: biParams.groupsScreen,
        button_name: 'search',
      }),
    );
    router.go('.', {
      title: value,
      partition: value ? EGroupPartition.ALL : params.partition,
    });
  }
}

Header.displayName = 'Header';
